import { Box, Button, Card, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, OutlinedInput, Select, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserProvider } from "../../../providers/useUserProvider";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { useEffect, useState } from "react";
import MaterialIcon from "../../../components/MaterialIcon";
import { checkIsProcessing, getRequestDetails, processRequest, reportRequest } from "../../../api/requests";
import { MEDICAL_HISTORY_KEYS, PRECOMPILED_REPORTS } from "../../../common/utils";
import { useModalDialog } from "../../../providers/useModalDialog";
import DocumentViewer from "../../../components/DocumentViewer";
import { analyzeDocument } from "../../../api/documents";
import { useDeviceTypeProvider } from "../../../providers/useDeviceTypeProvider";

const Analysis: React.FC = () => {
    const theme = useTheme();
    const { state } = useLocation();
    const id_richiesta: number = state?.id_richiesta;
    const id_provider: number = state?.id_provider;
    const { getDeviceType } = useDeviceTypeProvider();

    const { user } = useUserProvider();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const navigate = useNavigate();

    const [requestData, setRequestData] = useState<any>();
    const [selectedDocument, setSelectedDocument] = useState<any>(null);

    useEffect(() => {
        const loadRequestData = async () => {
            openLoadingDialog();

            try {
                let response = await checkIsProcessing({ id_richiesta, id_provider });

                if (!response.data.allowed) {
                    enqueueSnackbar("La richiesta è già in elaborazione da un altro utente", { variant: "warning" });
                    navigate("/dashboard");
                } else {
                    await elaborateRequest();

                    response = await getRequestDetails({ id_richiesta, id_provider });
                    setRequestData(response.data);
                }
            } catch (error) {
                enqueueSnackbar("Errore durante il caricamento della richiesta", { variant: "error" });
            }

            closeLoadingDialog();
        }

        const elaborateRequest = async () => {
            try {
                await processRequest({ id_richiesta, id_provider });
            } catch (error) {
                enqueueSnackbar("Errore durante l'assegnazione della richiesta", { variant: "error" });
            }
        }

        if (!user.userId || !user.userType) {
            navigate("/login");
            return;
        }

        if (!id_richiesta || !id_provider) {
            navigate("/error", { state: { errorStatus: 403 } });
            return;
        }

        loadRequestData();

        const elaborateIntervalId = setInterval(elaborateRequest, 30000);

        return () => {
            localStorage.removeItem("analysis_report");
            clearInterval(elaborateIntervalId);
        }
    }, []);

    const handleSendReport = async (report: string) => {
        openLoadingDialog();
        try {
            const response = await reportRequest({ id_richiesta, id_provider, testo_refertazione: report });
            enqueueSnackbar("Referto inviato con successo", { variant: "success" });
            navigate("/dashboard");
        } catch (error) {
            enqueueSnackbar("Errore durante l'invio del referto", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    return (
        <Box padding={getDeviceType() === 'mobile' ? "1rem" : "2rem"} boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.text.primary} fontWeight="bold">
                Analisi richiesta<MaterialIcon icon="arrow_right" />#{id_richiesta}
            </Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.text.primary}>
                Visualizza i dettagli della richiesta
            </Typography>
            <Grid container spacing="1rem">
                <Grid item xs={12} md={6} lg={8}>
                    {requestData && (
                        <DocumentAnalysis document={selectedDocument} id_provider={id_provider} onSendReport={handleSendReport} />
                    )}
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    {requestData && (
                        <>
                            <Summary request={requestData.richiesta} patient={requestData.paziente} />
                            <Box height="1rem" />
                            <Details documents={requestData.documenti} medicalHistory={requestData.anamnesi} exam={requestData.esame_obiettivo} onDocumentClick={(document) => setSelectedDocument(document)} />
                        </>
                    )}
                </Grid>
            </Grid >
        </Box >
    );
}

const DocumentAnalysis: React.FC<{ document: any, id_provider: number, onSendReport: (report: string) => void }> = ({ document, id_provider, onSendReport }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const { getDeviceType } = useDeviceTypeProvider();

    const [downloadedFile, setDownloadedFile] = useState<File | null>(null);
    const [reportText, setReportText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleFlareClick = async () => {
        if (!downloadedFile) {
            enqueueSnackbar("Devi prima selezionare un documento", { variant: "warning" });
            return;
        }

        if (localStorage.getItem("analysis_report") !== null && localStorage.getItem("analysis_report") !== "") {
            setReportText(localStorage.getItem("analysis_report") || "");
            return;
        }

        try {
            openLoadingDialog();
            const response = await analyzeDocument({ file: downloadedFile });

            if (response.data.valido && response.data.risposta) {
                setReportText(response.data.risposta);

                // Salva il referto nella cache mantenendo il riferimento alla path del documento per ottenere di nuovo il referto
                // quando si torna indietro
                localStorage.setItem("analysis_report", response.data.risposta);
            } else {
                enqueueSnackbar("Il documento non è un elettrocardiogramma", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Errore durante l'analisi del documento", { variant: "error" });
        } finally {
            closeLoadingDialog();
        }
    }

    useEffect(() => {
        localStorage.removeItem("analysis_report");
    }, [document]);

    const handleNavigateBack = () => {
        navigate("/dashboard", { replace: true });
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (!document) {
            return;
        }

        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (report: string) => {
        setReportText(report);
        handleMenuClose();
    };

    const handleSendReport = () => {
        onSendReport(reportText);
    }

    return (
        <>
            <Card variant="outlined">
                <DocumentViewer file={document} id_provider={id_provider} onFileDownload={(file) => setDownloadedFile(file)} />
            </Card>
            <Box height="1rem" />
            <Card variant="outlined">
                <Box padding={getDeviceType() === 'mobile' ? "1rem" : "2rem"} >
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexBasis="0">
                        <FormControl fullWidth
                            disabled={!document}>
                            <InputLabel htmlFor="precompiled-report">Seleziona un testo pre-compilato</InputLabel>
                            <Select
                                id="precompiled-report"
                                fullWidth
                                label="Seleziona un testo pre-compilato"
                                placeholder="Seleziona un'opzione"
                                value=""
                                onClick={handleMenuOpen}
                                readOnly
                                disabled={!document}>
                            </Select>
                            <Menu
                                id="precompiled-report-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}>
                                {PRECOMPILED_REPORTS.map((report, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={() => handleMenuItemClick(report)}>
                                        {report}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </FormControl>
                        <Box width="1rem" />
                        <IconButton onClick={handleFlareClick}>
                            <MaterialIcon icon="flare" />
                        </IconButton>
                    </Box>
                    <Box height="1rem" />
                    <FormControl fullWidth disabled={!document}>
                        <InputLabel htmlFor="report">Testo del referto</InputLabel>
                        <OutlinedInput
                            id="report"
                            label="Testo del referto"
                            fullWidth
                            multiline
                            rows={3}
                            value={reportText} // Aggiungi il valore del testo del referto
                            onChange={(event) => setReportText(event.target.value)} // Aggiorna il testo del referto quando cambia
                        />
                    </FormControl>
                    <Box height={getDeviceType() === 'mobile' ? "1rem" : "2rem"} />
                    {getDeviceType() === 'mobile' ? (
                        <>
                            <Button fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleSendReport}
                                disabled={reportText.length === 0}>
                                Invia referto
                            </Button>
                            <Box height="1rem" />
                            <Button fullWidth
                                variant="text"
                                color="primary"
                                onClick={handleNavigateBack}>
                                Torna indietro
                            </Button>
                        </>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="end">
                            <Button
                                variant="text"
                                color="primary"
                                onClick={handleNavigateBack}>
                                Torna indietro
                            </Button>
                            <Box width="1rem" />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendReport}
                                disabled={reportText.length === 0}>
                                Invia referto
                            </Button>
                        </Box>
                    )}
                </Box>
            </Card>
        </>
    );
}

const Summary: React.FC<{ request: any, patient: any }> = ({ request, patient }) => {
    return (
        <Card
            variant="outlined">
            <Grid container >
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Servizio" secondary={request.nome_servizio} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Data" secondary={request.timestamp_creazione} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Nome" secondary={patient.nome} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Cognome" secondary={patient.cognome} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Codice fiscale" secondary={patient.codice_fiscale} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Data di nascita" secondary={patient.data_nascita} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Email" secondary={patient.email} />
                    </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ListItem>
                        <ListItemText primary="Telefono" secondary={patient.telefono} />
                    </ListItem>
                </Grid>
            </Grid>
        </Card>
    );
}

const Details: React.FC<{ documents: any, medicalHistory: any, exam: any, onDocumentClick: (document: any) => void, currentDocument?: any }> = ({ documents, medicalHistory, exam, onDocumentClick, currentDocument }) => {
    const [activeTab, setActiveTab] = useState<"documents" | "medicalHistory" | "exam">("documents");
    const theme = useTheme();
    const { createModalDialog } = useModalDialog();

    let filteredMedicalHistory = Object.keys(medicalHistory).map((key) => {
        return {
            domanda: MEDICAL_HISTORY_KEYS.find(
                (category) => category.keys.find(
                    (k) => k.key === key))?.keys.find(
                        (k) => k.key === key)?.name || key,
            risposta: medicalHistory[key],
        };
    });
    filteredMedicalHistory = filteredMedicalHistory.filter((history: any) => ![null, undefined, "", 0, "No"].includes(history.risposta));
    let filteredExam = exam.filter((exam: any) => exam.risposta !== "");

    const handleDocumentClick = (document: any) => {
        const extension = document.nome.split(".").pop().toLowerCase();

        if (!["pdf", "png", "jpg", "jpeg"].includes(extension)) {
            createModalDialog({
                title: "Documento non visualizzabile",
                content: "Questo documento non può essere visualizzato in quanto non è un file PDF, PNG o JPG. Prova con un altro documento.",
                actions: [{ label: "Chiudi" }],
            });
            return;
        }

        onDocumentClick(document);
    }

    return (
        <Card
            variant="outlined">
            <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary">
                <Tab label="Documenti" value="documents" />
                <Tab label="Anamnesi" value="medicalHistory" />
                <Tab label="Esame" value="exam" />
            </Tabs>
            <Divider />
            {activeTab === "documents" && (
                <List>
                    {documents.map((document: any, index: number) => (
                        <ListItem key={index} button onClick={() => handleDocumentClick(document)}>
                            <MaterialIcon icon="description" color={theme.palette.text.secondary} />
                            <ListItemText primary={document.nome} secondary={document.categoria} sx={{ marginLeft: "1rem" }} />
                        </ListItem>
                    ))}
                    {documents.length === 0 && (
                        <ListItem>
                            <ListItemText primary="Nessun documento da mostrare" />
                        </ListItem>
                    )}
                </List>
            )}
            {activeTab === "medicalHistory" && (
                <>
                    {filteredMedicalHistory.length > 0 && (
                        <Grid container>
                            {filteredMedicalHistory.map((category, index) => (
                                <Grid item xs={12} md={6}>
                                    <ListItem key={index}>
                                        <ListItemText primary={category.domanda} secondary={category.risposta} />
                                    </ListItem>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {filteredMedicalHistory.length === 0 && (
                        <List>
                            <ListItem>
                                <ListItemText primary="Nessuna anamnesi da mostrare" />
                            </ListItem>
                        </List>
                    )}
                </>
            )}
            {activeTab === "exam" && (
                <List>
                    {filteredExam.map((exam: any, index: number) => (
                        <ListItem key={index}>
                            <ListItemText primary={exam.domanda} secondary={exam.risposta} />
                        </ListItem>
                    ))}
                    {filteredExam.length === 0 && (
                        <ListItem>
                            <ListItemText primary="Nessun esame da mostrare" />
                        </ListItem>
                    )}
                </List>
            )}
        </Card>
    );
}

export default Analysis;