import { createBrowserRouter } from "react-router-dom";
import App from "../pages/app/App";
import Login from "../pages/auth/login/Login";
import Dashboard from "../pages/app/dashboard/Dashboard";
import Logout from "../pages/auth/logout/Logout";
import Error from "../pages/error/Error";
import Main from "../pages/Main";
import Reports from "../pages/app/reports/Reports";
import Analysis from "../pages/app/analysis/Analysis";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        children: [
            {
                path: "/",
                element: <App />,
                children: [
                    {
                        index: true,
                        path: "dashboard",
                        element: <Dashboard />,
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                    {
                        path: "analysis",
                        element: <Analysis />,
                    },
                ]
            },
            {
                path: "login",
                element: <Login />
            },
            {
                path: "logout",
                element: <Logout />
            },
            {
                path: "error",
                element: <Error />
            },
            {
                path: "*",
                element: null
            },
        ]
    },
]);

export default router;