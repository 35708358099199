import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSwallowLoading } from "../providers/useSwallowLoading";
import { Box, IconButton, Toolbar, Typography, useTheme } from "@mui/material";
import { getDocumentFile } from "../api/documents";
import MaterialIcon from "./MaterialIcon";
import { useFullscreenDocument } from "../providers/useFullscreenDocument";
import { isChromiumBased } from "../common/utils";

const DocumentViewer: React.FC<{ file: any, id_provider: number, onFileDownload?: (file: File) => void }> = ({ file, id_provider, onFileDownload }) => {
    const theme = useTheme();

    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const { showFullscreenDocument, initializeFullscreenDocument, hideFullscreenDocument, clearFullscreenDocument } = useFullscreenDocument();

    useEffect(() => {
        const loadDocumentFile = async () => {
            openLoadingDialog();
            try {
                const response = await getDocumentFile({ path: file.path, id_provider });

                const _file = new File([response.data], file.nome, { type: response.headers["content-type"] });

                const reader = new FileReader();
                reader.onload = () => {
                    const result = (reader.result as string).split(",")[1];

                    let url =
                        _file.type === "application/pdf" ?
                            'data:application/pdf;base64,' + result :
                            'data:' + _file.type + ';base64,' + result;

                    // Se il browser è chrome, o chromium, nascondi la toolbar
                    if (isChromiumBased()) {
                        url += "#toolbar=0&navpanes=0";
                    }

                    setFileUrl(url);
                    setFileType(_file.type);

                    initializeFullscreenDocument(file, id_provider, url);

                    if (onFileDownload) {
                        onFileDownload(_file);
                    }
                }
                reader.readAsDataURL(_file);
            } catch (error) {
                enqueueSnackbar("Errore durante il caricamento del documento", { variant: "error" });
            } finally {
                closeLoadingDialog();
            }
        }


        if (file) {
            loadDocumentFile();
        }

        return () => {
            hideFullscreenDocument();
            clearFullscreenDocument();
        }
    }, [file]);

    const toggleFullscreenMode = () => {
        showFullscreenDocument();
    }

    const handleDownload = () => {
        if (!fileUrl || !file) {
            return;
        }

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = file.nome;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    aspectRatio: "16 / 9",
                }}>
                {fileUrl && (
                    <>
                        {(fileType === "application/pdf") ? (
                            <iframe
                                src={fileUrl}
                                title={file.nome}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                }} />
                        ) : (
                            <img
                                src={fileUrl}
                                alt={file.nome}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                }} />
                        )}
                    </>
                )}
                {!fileUrl && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        sx={{
                            width: "100%",
                            height: "100%",
                        }}>
                        <MaterialIcon icon="description" color={theme.palette.text.secondary} size="4rem" />
                        <Box height="1rem" />
                        <Typography variant="h6" color={theme.palette.text.secondary}>
                            Nessun documento selezionato
                        </Typography>
                    </Box>
                )}
            </Box>
            <Toolbar sx={{ bgcolor: "#212121" }}>
                {!file && (
                    <Typography variant="h6" color={theme.palette.textDark.primary} textOverflow={"ellipsis"} overflow="hidden" white-space="nowrap"
                    >
                        Seleziona un documento dall'elenco
                    </Typography>
                )}
                {file && (
                    <>
                        <Typography variant="h6" color={theme.palette.textDark.primary} textOverflow={"ellipsis"} overflow="hidden" white-space="nowrap">
                            File: {file.nome}
                        </Typography>
                        <IconButton sx={{ marginLeft: "auto" }} onClick={toggleFullscreenMode}>
                            <MaterialIcon icon="fullscreen" color={theme.palette.textDark.primary} />
                        </IconButton>
                        <Box width="1rem" />
                        <IconButton onClick={handleDownload}>
                            <MaterialIcon icon="download" color={theme.palette.textDark.primary} />
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </>
    );
}

export default DocumentViewer;
