import { Box, Button, Typography, useTheme } from "@mui/material";
import MaterialIcon from "../../components/MaterialIcon";
import { useLocation, useNavigate } from "react-router-dom";

const Error: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { state } = useLocation();

    let statusCode = state.errorStatus ?? 404;
    let message = "";
    let description = "";

    switch (statusCode) {
        case 401:
            message = "Non autorizzato";
            description = "Non hai i permessi necessari per visualizzare questa pagina";
            break;
        case 403:
            message = "Vietato";
            description = "Non hai i permessi necessari per visualizzare questa pagina";
            break;
        case 404:
            message = "Pagina non trovata";
            description = "La pagina che stai cercando non esiste";
            break;
        default:
            message = "Errore";
            description = "Si è verificato un errore";
            break;
    }

    const handleHomeNavigation = () => {
        navigate("/");
    }

    return (
        <Box
            width="100svw"
            height="100svh"
            bgcolor={theme.palette.background.default}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <MaterialIcon icon="error" color={theme.palette.text.primary} size="8rem" />
            <Typography variant="h2" color={theme.palette.text.primary} textAlign="center" gutterBottom sx={{ marginTop: "2rem" }}>{statusCode}</Typography>
            <Typography variant="h4" color={theme.palette.text.primary} textAlign="center" gutterBottom>{message}</Typography>
            <Typography variant="body1" color={theme.palette.text.primary} textAlign="center" sx={{ marginBottom: "2rem" }}>{description}</Typography>
            <Button
                variant="contained"
                onClick={handleHomeNavigation}>
                Torna alla home
            </Button>
        </Box >
    );
}

export default Error;