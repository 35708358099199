import { Box, Card, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { useUserProvider } from "../../../providers/useUserProvider";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SortableTable, { HeadCell } from "../../../components/SortableTable";
import { useSnackbar } from "notistack";
import { useSwallowLoading } from "../../../providers/useSwallowLoading";
import { getReports } from "../../../api/reports";
import { useDeviceTypeProvider } from "../../../providers/useDeviceTypeProvider";

export const Reports: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();
    const { getDeviceType } = useDeviceTypeProvider();

    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();

    const [reports, setReports] = useState<any[]>([]);

    useEffect(() => {
        const loadReports = async () => {
            openLoadingDialog();
            try {
                const response = await getReports({ id_utente: user.userId! });
                setReports(response.data);
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            } finally {
                closeLoadingDialog();
            }
        }

        if (!user.userId || !user.userType) {
            navigate("/login");
            return;
        }

        loadReports();
    }, []);

    const columns: HeadCell<any>[] = [
        { id: 'id_referto', label: 'ID', numeric: false, disablePadding: false, disableSort: false },
        { id: 'nome_provider', label: 'Provider', numeric: false, disablePadding: false, disableSort: false },
        { id: 'testo_refertazione', label: 'Stato', numeric: false, disablePadding: false, disableSort: false },
        { id: 'timestamp_creazione', label: 'Data referto', numeric: false, disablePadding: false, disableSort: true },
    ];

    const Row = (report: any) => {
        return (
            <TableRow key={report.id_referto}>
                <TableCell>{report.id_referto}</TableCell>
                <TableCell>{report.nome_provider}</TableCell>
                <TableCell>{report.testo_refertazione}</TableCell>
                <TableCell>{report.timestamp_creazione}</TableCell>
            </TableRow>
        );
    }

    return (
        <Box padding={getDeviceType() === 'mobile' ? '1rem' : '2rem'} boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.text.primary} fontWeight="bold">I tuoi referti</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.text.primary}>
                Visualizza i referti da te creati sulla piattaforma
            </Typography>
            {user.userId && user.userType && (
                <>
                    <Card
                        variant="outlined">
                        <SortableTable
                            headCells={columns}
                            renderRow={Row}
                            rows={reports}
                            rowsPerPageOptions={[5, 10, 25]}
                        />
                    </Card>
                </>
            )}
        </Box>
    );
}

export default Reports;