import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import router from './config/router';
import { ThemeProvider } from '@mui/material';
import { theme } from './config/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import { ToastError, ToastInfo, ToastSuccess, ToastWarning } from './components/Toast';
import { SwallowLoadingProvider } from './providers/useSwallowLoading';
import { ModalDialogProvider } from './providers/useModalDialog';
import { FullscreenDocumentProvider } from './providers/useFullscreenDocument';
import { pdfjs } from 'react-pdf';
import { DeviceTypeProvider } from './providers/useDeviceTypeProvider';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <DeviceTypeProvider>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            Components={{
              default: ToastInfo,
              success: ToastSuccess,
              error: ToastError,
              warning: ToastWarning,
              info: ToastInfo
            }}>
            <ModalDialogProvider>
              <SwallowLoadingProvider>
                <FullscreenDocumentProvider>
                  <RouterProvider router={router} />
                </FullscreenDocumentProvider>
              </SwallowLoadingProvider>
            </ModalDialogProvider>
          </SnackbarProvider>
        </DeviceTypeProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);