export function extractDateOfBirthFromItalianFiscalCode(fiscalCode: string): Date | null {
    if (fiscalCode.length !== 16) {
        console.error("Il codice fiscale deve essere lungo 16 caratteri.");
        return null;
    }

    const yearPart = fiscalCode.substr(6, 2);
    const monthPart = fiscalCode.charAt(8);
    const dayPart = parseInt(fiscalCode.substr(9, 2), 10);

    const monthMap: { [key: string]: number } = {
        A: 1, B: 2, C: 3, D: 4, E: 5, H: 6, L: 7, M: 8, P: 9, R: 10, S: 11, T: 12
    };

    const month = monthMap[monthPart];
    if (month === undefined) {
        console.error("Mese non valido nel codice fiscale.");
        return null;
    }

    let currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // +1 perché i mesi sono indicizzati da 0 a 11
    if (currentMonth < month) {
        currentYear -= 1; // Riduci l'anno corrente se il mese attuale è inferiore al mese estratto
    }

    let year = parseInt(yearPart, 10);
    year += year < 100 ? (year > currentYear % 100 ? 1900 : 2000) : 0;

    const day = dayPart > 40 ? dayPart - 40 : dayPart;

    const dateOfBirth = new Date(year, month - 1, day); // -1 perché i mesi sono indicizzati da 0 a 11

    if (isNaN(dateOfBirth.getTime())) {
        console.error("Data di nascita non valida.");
        return null;
    }

    return dateOfBirth;
}

export enum MedicalHistoryCategory {
    General = 'Anamnesi Generale',
    Family = 'Anamnesi Familiare',
    Physiological = 'Anamnesi Fisiologica',
    Pathological = 'Anamnesi Patologica',
    OtherPathological = 'Altri tipi di patologie',
    More = 'Ulteriori annotazioni',
}

export const MEDICAL_HISTORY_KEYS = [
    {
        category: MedicalHistoryCategory.General,
        keys: [
            { key: 'altezza', name: 'Altezza (cm)', type: 'number' },
            { key: 'peso', name: 'Peso (kg)', type: 'number' },
        ]
    },
    {
        category: MedicalHistoryCategory.Family,
        keys: [
            { key: 'malattie_cardiache', name: 'Malattie Cardiache', type: 'radio' },
            { key: 'ipertensione', name: 'Ipertensione', type: 'radio' },
            { key: 'infarto_del_miocardio', name: 'Infarto Del Miocardio', type: 'radio' },
            { key: 'diabete_mellito', name: 'Diabete Mellito', type: 'radio' },
            { key: 'colesterolo_alto', name: 'Colesterolo Alto', type: 'radio' },
            { key: 'malattie_polmonari', name: 'Malattie Polmonari', type: 'radio' },
            { key: 'tumori', name: 'Tumori', type: 'radio' },
            { key: 'sordita', name: 'Sordità', type: 'radio' },
            { key: 'morte_improvvisa', name: 'Morte Improvvisa', type: 'radio' },
        ]
    },
    {
        category: MedicalHistoryCategory.Physiological,
        keys: [
            { key: 'nato_da_parto_naturale', name: 'È nato da parto naturale o a termine', type: 'radio' },
            { key: 'alcol', name: 'Alcol', type: 'radio' },
            { key: 'allattato_seno', name: 'Allattato Seno', type: 'radio' },
            {
                key: 'assume_farmaci', name: 'Assume farmaci e/o integratori', type: 'radio',
                ifYes: { key: 'text_assume_farmaci', name: 'Dettagli Farmaci/Integratori', type: 'text' }
            },
            {
                key: 'fumi', name: 'Fumi', type: 'radio',
                ifYes: { key: 'text_fumi', name: 'Dettagli Fumo', type: 'text' }
            },
        ]
    },
    {
        category: MedicalHistoryCategory.Pathological,
        keys: [
            { key: 'allergie', name: 'Allergie', type: 'radio' },
            { key: 'anemia', name: 'Anemia', type: 'radio' },
            { key: 'palpitazioni', name: 'Palpitazioni', type: 'radio' },
            { key: 'pressione_arteriosa_alta', name: 'Pressione Arteriosa Alta', type: 'radio' },
            { key: 'malattie_fegato_tratto_biliare', name: 'Malattie Fegato Tratto Biliare', type: 'radio' },
            { key: 'malattie_endocrine', name: 'Malattie Endocrine', type: 'radio' },
            { key: 'asma_bronchiale', name: 'Asma Bronchiale', type: 'radio' },
            { key: 'epilessia', name: 'Epilessia', type: 'radio' },
            { key: 'dolore_toracico', name: 'Dolore Toracico', type: 'radio' },
            { key: 'cafalee_ricorrenti', name: 'Cefalee Ricorrenti', type: 'radio' },
            { key: 'neoplasie', name: 'Neoplasie', type: 'radio' },
            { key: 'mal_sistema_muscolo_scheletrico', name: 'Mal Sistema Muscolo Scheletrico', type: 'radio' },
            { key: 'disturbi_apparato_respiratorio', name: 'Disturbi Apparato Respiratorio', type: 'radio' },
            { key: 'svenimenti', name: 'Svenimenti', type: 'radio' },
            { key: 'vertigini', name: 'Vertigini', type: 'radio' },
            { key: 'disturbi_visivi', name: 'Disturbi Visivi', type: 'radio' },
            { key: 'malattie_neurologiche', name: 'Malattie Neurologiche', type: 'radio' },
            { key: 'malattie_psichiatriche', name: 'Malattie Psichiatriche', type: 'radio' },
            { key: 'otiti_sinusiti', name: 'Otiti / Sinusiti', type: 'radio' },
            { key: 'malattie_cuore', name: 'Malattie Cuore', type: 'radio' },
            { key: 'acufeni', name: 'Acufeni', type: 'radio' },
            { key: 'malattie_gastrointestinali', name: 'Malattie Gastrointestinali', type: 'radio' },
            { key: 'mal_reni_tratto_urinario', name: 'Mal Reni / Tratto Urinario', type: 'radio' },
            {
                key: 'interventi_chirurgici', name: 'Interventi Chirurgici', type: 'radio',
                ifYes: { key: 'text_interventi_chirurgici', name: 'Dettagli Interventi Chirurgici', type: 'text' }
            },
            {
                key: 'fratture_distorsioni_gravi', name: 'Fratture Distorsioni Gravi', type: 'radio',
                ifYes: { key: 'text_fratture_distorsioni_gravi', name: 'Dettagli Fratture/Distorsioni', type: 'text' }
            },
            { key: 'obesità', name: 'Obesità', type: 'radio' },
            { key: 'malattie_tiroidee', name: 'Malattie Tiroidee', type: 'radio' },
            { key: 'malattie_nurologiche', name: 'Malattie Neurologiche', type: 'radio' },
            { key: 'asma_allergie', name: 'Asma/Allergie', type: 'radio' },
        ]
    },
    {
        category: MedicalHistoryCategory.OtherPathological,
        keys: [
            {
                key: 'usa_occhiali', name: 'Usa Occhiali o lenti a contatto', type: 'radio',
                ifYes: { key: 'patologia_occhiali', name: 'Dettagli Occhiali/Lenti', type: 'text' }
            },
            {
                key: 'dispositivi_acustici', name: 'Usa dispositivi acustici', type: 'radio',
                ifYes: { key: 'patologia_dispositivi_acustici', name: 'Dettagli Dispositivi Acustici', type: 'text' }
            },
            {
                key: 'plantari_ausili_odontoiatrici', name: 'Usa plantari o ausili odontoiatrici', type: 'radio',
                ifYes: { key: 'patologia_plantari_ausili_odontoiatrici', name: 'Dettagli Plantari/Ausili Odontoiatrici', type: 'text' }
            },
        ]
    },
    {
        category: MedicalHistoryCategory.More,
        keys: [
            { key: 'gravidanza_in_corso', name: 'Gravidanza In Corso', type: 'radio' },
            {
                key: 'sport', name: 'Sport', type: 'radio',
                ifYes: { key: 'text_sport', name: 'Dettagli Sport', type: 'text' }
            },
            { key: 'anomalie_ciclo', name: 'Anomalie Ciclo', type: 'radio' },
            {
                key: 'lavora', name: 'Lavora', type: 'radio',
                ifYes: { key: 'text_lavoro', name: 'Dettagli Lavoro', type: 'text' }
            },
            {
                key: 'altro', name: 'Altro', type: 'radio',
                ifYes: { key: 'text_altro', name: 'Dettagli Altro', type: 'text' },
            },
        ]
    },
];

export const PRECOMPILED_REPORTS = [
    "Ritmo sinusale regolare a [frequenza] bpm. Asse in equilibrio, buona la ripolarizzazione. ECG nei limiti della norma.",
    "Ritmo sinusale regolare a [frequenza] bpm. Presenza di [anomalia minore], tuttavia, complessivamente entro i limiti della norma.",
    "Ritmo sinusale a [frequenza] bpm con [anomalia moderata]. Si consiglia ulteriore valutazione per escludere [condizione specifica].",
    "Ritmo sinusale a [frequenza] bpm. Evidenza di [anomalia grave], necessaria immediata valutazione cardiologica per [condizione specifica].",
];

export const isChromiumBased = () => {
    const userAgent = navigator.userAgent;
    const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
    const isOpera = /OPR/.test(userAgent);
    const isEdge = /Edg/.test(userAgent);

    return isChrome || isOpera || isEdge;
}