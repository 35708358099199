import apiClient from "./base";

export const getRequests = async () => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/ottieni_richieste.php", {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const getRequestDetails = async (params: { id_richiesta: number, id_provider: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/ottieni_dettagli_richiesta.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const checkIsProcessing = async (params: { id_richiesta: number, id_provider: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/controlla_elaborazione.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const processRequest = async (params: { id_richiesta: number, id_provider: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/elabora_richiesta.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const reportRequest = async (params: { id_richiesta: number, id_provider: number, testo_refertazione: string }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/referta.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}