import apiClient from "./base";

export const getReports = async (params: { id_utente: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/ottieni_referti.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}

export const report = async (params: { id_richiesta: number, id_provider: number, testo_refertazione: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/referta.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
}