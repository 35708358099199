import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface DialogAction {
    label: string;
    onClick?: () => void;
}

// Define the dialog state type
interface DialogState {
    id: string;
    title?: string | null;
    content?: ReactNode | null;
    actions?: DialogAction[];
    canIgnore?: boolean;
}

// Initialize the context with a default value of undefined
const DialogContext = createContext<{
    createModalDialog: ({ title, content, actions }: { title?: string, content?: ReactNode, actions?: DialogAction[], canIgnore?: boolean }) => string;
    closeModalDialog: (id: string) => void;
    closeAllDialogs: () => void;
} | undefined>(undefined);

// Create a provider
interface DialogProviderProps {
    children: ReactNode;
}

export function ModalDialogProvider({ children }: DialogProviderProps) {
    const [dialogs, setDialogs] = useState<DialogState[]>([]);

    const createModalDialog = ({ title, content, actions }: { title?: string, content?: ReactNode, actions?: DialogAction[], canIgnore?: boolean }) => {
        if (!title && !content) {
            throw new Error('At least one of title or content must be provided');
        }

        const id = Math.random().toString(36).substring(2, 9);

        if (actions) {
            actions = actions.map(action => ({
                ...action,
                onClick: () => {
                    if (action.onClick)
                        action.onClick();
                    closeModalDialog(id);
                },
            }));
        }

        setDialogs(prevDialogs => [
            ...prevDialogs,
            { id, title, content, actions },
        ]);

        return id;
    };

    const closeModalDialog = (id: string) => {
        setDialogs(prevDialogs => prevDialogs.filter(dialog => dialog.id !== id));
    };

    const closeAllDialogs = () => {
        setDialogs([]);
    };

    return (
        <DialogContext.Provider value={{ createModalDialog, closeModalDialog, closeAllDialogs }}>
            {children}
            {dialogs.map(dialog => (
                <Dialog
                    key={dialog.id}
                    open={true}
                    onClose={(event, reason) => {
                        if (reason === 'backdropClick' && !dialog.canIgnore) {
                            return;
                        }

                        closeModalDialog(dialog.id)
                    }}
                    fullWidth
                    maxWidth="xs"
                    disableEscapeKeyDown={!dialog.canIgnore}
                >
                    {dialog.title && (
                        <DialogTitle>{dialog.title}</DialogTitle>
                    )}
                    {dialog.content && (
                        <DialogContent>
                            {dialog.content}
                        </DialogContent>
                    )}
                    {dialog.actions && (
                        <DialogActions>
                            {dialog.actions.map((action, index) => (
                                <Button key={index} onClick={action.onClick}>{action.label}</Button>
                            ))}
                        </DialogActions>
                    )}
                </Dialog>
            ))}
        </DialogContext.Provider>
    );
}

// Custom hook to use the context
export function useModalDialog() {
    const context = useContext(DialogContext);
    if (context === undefined) {
        throw new Error('useModalDialog must be used within a ModalDialogProvider');
    }
    return context;
}