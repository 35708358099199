import apiClient from "./base";

export const getStats = async () => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/statistiche.php", {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
};