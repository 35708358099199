import { Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import MaterialIcon from "./MaterialIcon";
import { useUserProvider } from "../providers/useUserProvider";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Route {
    title: string;
    icon: string;
    path?: string;
    children?: Route[];
}

const ROUTES: Route[] = [
    { title: 'Dashboard', icon: 'bar_chart_4_bars', path: '/dashboard' },
    { title: 'Referti', icon: 'description', path: '/reports' },
];

const DrawerList: React.FC<{ toggleDrawer: () => void }> = ({ toggleDrawer }) => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const routes = ROUTES;

    return (
        <Box>
            <List component="nav">
                {routes.map((route) => (
                    <DrawerListItem key={route.title} route={route} toggleDrawer={toggleDrawer} isSmallScreen={isSmallScreen} />
                ))}
            </List>
        </Box>
    );
}

const DrawerListItem: React.FC<{ route: Route, toggleDrawer: () => void, isSmallScreen: boolean }> = ({ route, toggleDrawer, isSmallScreen }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    const handleItemClick = () => {
        if (route.path) {
            // Redirect to the specified path
            navigate(route.path);

            if (isSmallScreen)
                toggleDrawer();
        }

        if (route.children) {
            setIsCollapsed(!isCollapsed);
        }
    }

    let isSelected = false;

    if (route.path) {
        isSelected = location.pathname.includes(route.path);
    }

    return (
        <Box>
            <ListItem
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                }}>
                <ListItemButton
                    onClick={handleItemClick}
                    sx={{
                        width: "100%",
                        borderLeft: isSelected ? `4px solid ${theme.palette.primary.main}` : "none",
                        borderRadius: isSelected ? "0 .5rem .5rem 0" : ".5rem",
                    }}>
                    <MaterialIcon icon={route.icon} color={isSelected ? theme.palette.primary.main : theme.palette.text.secondary} />
                    <ListItemText
                        primary={route.title}
                        sx={{
                            marginLeft: "1rem",
                            marginRight: "auto",
                            color: isSelected ? theme.palette.primary.main : theme.palette.text.secondary,
                        }} />
                    {route.children && (
                        <MaterialIcon icon={isCollapsed ? 'expand_more' : 'expand_less'} />
                    )}
                </ListItemButton>
            </ListItem>
            {route.children && (
                <Collapse in={!isCollapsed} timeout="auto" unmountOnExit sx={{ width: "100%", boxSizing: "border-box", paddingLeft: "1rem" }}>
                    <List component="div" disablePadding>
                        {route.children.map((child) => (
                            <DrawerListItem key={child.title} route={child} toggleDrawer={toggleDrawer} isSmallScreen={isSmallScreen} />
                        ))}
                    </List>
                </Collapse>
            )}
        </Box>
    );
}

export default DrawerList;