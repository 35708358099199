import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDrag } from '@use-gesture/react';
import Logo from "./Logo";

const Ruler: React.FC<{ rotation: number, length: number, showMoreTicks?: boolean }> = ({ rotation, length, showMoreTicks = true }) => {
    const theme = useTheme();
    const [position, setPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

    const bind = useDrag((state) => {
        setPosition({ x: state.offset[0], y: state.offset[1] });
    }, {
        from: () => [position.x, position.y],
        bounds: { left: 0, right: window.innerWidth, top: 0, bottom: window.innerHeight },
        rubberband: 0.1,
    });

    return (
        <Box
            {...bind()}
            height="8rem"
            bgcolor="rgba(26, 35, 126, 0.08)"
            style={{
                backdropFilter: 'blur(8px)',
                position: 'absolute',
                top: `${position.y}px`,
                left: `${position.x}px`,
                zIndex: theme.zIndex.tooltip,
                cursor: 'grab',
                borderBottom: `2px solid ${theme.palette.text.secondary}`,
                boxShadow: theme.shadows[4],
                transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
                userSelect: 'none',
            }}
        >
            <Box
                position="relative"
                width="100%"
                height="100%"
                padding="0 .5cm"
                boxSizing="border-box"
                display="flex"
                flexDirection="row"
            >
                {Array.from({ length: length }, (_, index) => (
                    <RulerTick
                        key={index}
                        tickColor={theme.palette.text.primary}
                        number={index}
                        showLastTick={index === length - 1}
                        showMoreTicks={showMoreTicks}
                    />
                ))}
            </Box>
            <Logo
                primaryColor="#000"
                style={{
                    height: '2rem',
                    position: 'absolute',
                    bottom: '1rem',
                    right: '1rem',
                    zIndex: theme.zIndex.tooltip + 1,
                    opacity: 0.32,
                }}
            />
        </Box>
    );
}

const RulerTick: React.FC<{ tickColor: any, number: number, showLastTick: boolean, showMoreTicks: boolean }> = ({ tickColor, number, showLastTick, showMoreTicks }) => {
    return (
        <Box
            width="40px"
            position="relative"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="start">
            <Box
                width="1px"
                height="1.5rem"
                bgcolor={tickColor} />
            {showMoreTicks && (
                [...Array(9)].map((_, index: number) => (
                    <Box
                        key={index}
                        width="1px"
                        height={index === 4 ? "1rem" : ".75rem"}
                        bgcolor={tickColor}
                    />
                ))
            )}
            {!showMoreTicks && (
                [...Array(4)].map((_, index) => (
                    <Box
                        key={index}
                        width="1px"
                        height=".75rem"
                        bgcolor={tickColor}
                    />
                ))
            )}
            <Box
                width="1px"
                height="1.5rem"
                marginRight="-1px"
                bgcolor={tickColor}
                visibility={showLastTick ? 'visible' : 'hidden'} />
            <Typography
                position="absolute"
                top="1.5rem"
                left="0"
                variant="caption"
                color={tickColor}
                sx={{
                    userSelect: 'none',
                    transform: 'translateX(-50%)',
                }}
            >
                {number}
            </Typography>
            {showLastTick && (
                <Typography
                    position="absolute"
                    top="1.5rem"
                    right="0"
                    variant="caption"
                    color={tickColor}
                    sx={{
                        userSelect: 'none',
                        transform: 'translateX(50%)',
                    }}
                >
                    {number + 1}
                </Typography>
            )}
        </Box>
    );
}

export default Ruler;