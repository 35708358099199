import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Box, Card, Divider, FormControl, FormControlLabel, IconButton, InputLabel, OutlinedInput, Switch, Toolbar, Typography, useTheme } from '@mui/material';
import MaterialIcon from '../components/MaterialIcon';
import { enqueueSnackbar } from 'notistack';
import Ruler from '../components/Ruler';
import { useDeviceTypeProvider } from './useDeviceTypeProvider';

// Define the dialog state type
interface FullscreenState {
    document: any;
    id_provider: number;
    fileUrl: string;
}

// Initialize the context with a default value of undefined
const FullscreenDocumentContext = createContext<{
    initializeFullscreenDocument: (document: any, id_provider: number, fileUrl: string) => void;
    clearFullscreenDocument: () => void;
    showFullscreenDocument: () => void;
    hideFullscreenDocument: () => void;
} | undefined>(undefined);

// Create a provider
interface DialogProviderProps {
    children: ReactNode;
}

export function FullscreenDocumentProvider({ children }: DialogProviderProps) {
    const { getDeviceType } = useDeviceTypeProvider();
    const [fullscreenState, setFullscreenState] = useState<FullscreenState | null>(null);
    const [isFullscreenVisible, setIsFullscreenVisible] = useState(false);
    const [isRulerVisible, setIsRulerVisible] = useState(false);
    const [rulerRotation, setRulerRotation] = useState(0);
    const [rulerLength, setRulerLength] = useState(getDeviceType() === 'mobile' ? 8 : 30);
    const [rulerShowMoreTicks, setRulerShowMoreTicks] = useState(false);
    const [fileType, setFileType] = useState("");
    const theme = useTheme();

    const initializeFullscreenDocument = (document: any, id_provider: number, fileUrl: string) => {
        setFullscreenState({ document, id_provider, fileUrl });
        setFileType(document.nome.split(".").pop().toLowerCase());
    }

    const clearFullscreenDocument = () => {
        setFullscreenState(null);
    }

    const showFullscreenDocument = () => {
        setIsFullscreenVisible(true);
    }

    const hideFullscreenDocument = () => {
        setIsFullscreenVisible(false);
        setIsRulerVisible(false);
    }

    const toggleRuler = () => {
        setIsRulerVisible(!isRulerVisible);
    }

    const downloadFile = () => {
        if (!fullscreenState) return;

        const link = document.createElement('a');
        link.href = fullscreenState.fileUrl;
        link.download = fullscreenState.document.nome;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <FullscreenDocumentContext.Provider value={{ initializeFullscreenDocument, clearFullscreenDocument, showFullscreenDocument, hideFullscreenDocument }}>
            {children}
            {fullscreenState && (
                <Box
                    className="fullscreen-div"
                    width="100dvw"
                    height="100dvh"
                    bgcolor={theme.palette.background.default}
                    position="fixed"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    zIndex={theme.zIndex.modal}
                    display="flex"
                    flexDirection="column"
                    sx={{
                        pointerEvents: isFullscreenVisible ? 'auto' : 'none',
                        boxShadow: isFullscreenVisible ? theme.shadows[24] : 'none',
                        transform: isFullscreenVisible ? 'none' : 'translateY(100%)',
                        transition: 'transform 0.3s',
                    }}>
                    <Toolbar>
                        <IconButton onClick={hideFullscreenDocument}>
                            <MaterialIcon icon="close" color={theme.palette.text.primary} />
                        </IconButton>
                        <Box width="1rem" />
                        <Typography variant="h6" color={theme.palette.text.primary}>
                            {fullscreenState.document.nome}
                        </Typography>
                        <Box width="1rem" />
                        <IconButton sx={{ marginLeft: "auto" }} onClick={toggleRuler}>
                            <MaterialIcon icon="straighten" color={theme.palette.text.primary} />
                        </IconButton>
                        <Box width="1rem" />
                        <IconButton onClick={downloadFile}>
                            <MaterialIcon icon="download" color={theme.palette.text.primary} />
                        </IconButton>
                    </Toolbar>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflow: "hidden",
                        }}>
                        {fileType === "pdf" && (
                            // TODO: Make pdf adapt to the container size by default zoom
                            <iframe
                                src={fullscreenState.fileUrl}
                                title={fullscreenState.document.nome}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none",
                                }} />
                        )}
                        {(fileType === "png" || fileType === "jpg" || fileType === "jpeg") && (
                            <img
                                src={fullscreenState.fileUrl}
                                alt={fullscreenState.document.nome}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                }} />
                        )}
                        {isRulerVisible && (
                            <>
                                <Ruler
                                    rotation={rulerRotation}
                                    length={rulerLength}
                                    showMoreTicks={rulerShowMoreTicks} />
                                <Card
                                    sx={{
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        margin: getDeviceType() === 'mobile' ? '1rem' : '2rem',
                                        padding: '0',
                                        boxSizing: 'border-box',
                                        zIndex: theme.zIndex.tooltip,
                                        maxWidth: getDeviceType() === 'mobile' ? 'calc(100% - 2rem)' : '24rem',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Toolbar>
                                        <Typography variant="h6">Impostazioni righello</Typography>
                                        <IconButton
                                            sx={{ marginLeft: 'auto' }}
                                            onClick={toggleRuler}>
                                            <MaterialIcon icon="close" />
                                        </IconButton>
                                    </Toolbar>
                                    <Divider />
                                    <Box padding="2rem">
                                        <FormControl fullWidth>
                                            <InputLabel>Lunghezza (cm)</InputLabel>
                                            <OutlinedInput
                                                type="number"
                                                label="Lunghezza (cm)"
                                                value={rulerLength}
                                                onChange={(event) => {
                                                    if (parseInt(event.target.value) >= 4) {
                                                        setRulerLength(parseInt(event.target.value));
                                                    } else {
                                                        enqueueSnackbar("La lunghezza minima del righello è di 4 cm", { variant: "warning" });
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <Box height="1rem" />
                                        <FormControl fullWidth>
                                            <InputLabel>Rotazione (gradi)</InputLabel>
                                            <OutlinedInput
                                                type="number"
                                                label="Rotazione (gradi)"
                                                value={rulerRotation}
                                                onChange={(event) => {
                                                    if (parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 360) {
                                                        setRulerRotation(parseInt(event.target.value));
                                                    } else {
                                                        enqueueSnackbar("La rotazione deve essere compresa tra 0 e 360 gradi", { variant: "warning" });
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <Box height="1rem" />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={rulerShowMoreTicks}
                                                    onChange={(event) => setRulerShowMoreTicks(event.target.checked)}
                                                />
                                            }
                                            label="Mostra più tacche"
                                        />
                                    </Box>
                                </Card>
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </FullscreenDocumentContext.Provider>
    );
}

// Custom hook to use the context
export function useFullscreenDocument() {
    const context = useContext(FullscreenDocumentContext);
    if (context === undefined) {
        throw new Error('useFullscreenDocument must be used within a FullscreenDocumentProvider');
    }
    return context;
}
