import React, { useEffect, useState } from 'react';
import { Box, Card, FormControl, Grid, IconButton, OutlinedInput, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useUserProvider } from '../../../providers/useUserProvider';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSwallowLoading } from '../../../providers/useSwallowLoading';
import { getStats } from '../../../api/stats';
import MaterialIcon from '../../../components/MaterialIcon';
import { getRequests } from '../../../api/requests';
import SortableTable, { HeadCell } from '../../../components/SortableTable';
import { useDeviceTypeProvider } from '../../../providers/useDeviceTypeProvider';

const Dashboard: React.FC = () => {
    const theme = useTheme();
    const { user } = useUserProvider();
    const navigate = useNavigate();
    const { getDeviceType } = useDeviceTypeProvider();

    useEffect(() => {
        if (!user.userId || !user.userType) {
            navigate("/login");
            return;
        }
    }, []);

    return (
        <Box padding={getDeviceType() === 'mobile' ? "1rem" : "2rem"} boxSizing='border-box'>
            <Typography variant="h4" gutterBottom color={theme.palette.text.primary} fontWeight="bold">Dashboard</Typography>
            <Typography variant="body1" marginBottom="1rem" color={theme.palette.text.primary}>
                Visualizza le statistiche e i dati principali della piattaforma
            </Typography>
            {user.userId && user.userType && (
                <>
                    <Stats />
                    <Box height="2rem" />
                    <List />
                </>
            )}
        </Box>
    );
};

const Stats: React.FC = () => {
    const { user } = useUserProvider();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const [stats, setStats] = useState<any>(null);

    useEffect(() => {
        const loadStats = async () => {
            openLoadingDialog();
            try {
                const response = await getStats();
                setStats(response.data);
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            } finally {
                closeLoadingDialog();
            }
        };

        loadStats();
    }, []);

    return (
        <Box>
            <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold">Statistiche</Typography>
            <Box height="1rem" />
            {stats ? (
                <Grid container spacing="1rem">
                    <Grid item xs={12} sm={6} md={4}>
                        <Card variant='outlined'>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                padding="2rem 0">
                                <Typography variant='h3' color={theme.palette.text.secondary}>
                                    {stats.giornalieri}
                                </Typography>
                                <Typography variant='h6' color={theme.palette.text.secondary}>
                                    Referti giornalieri
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card variant='outlined'>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                padding="2rem 0">
                                <Typography variant='h3' color={theme.palette.text.secondary}>
                                    {stats.mensili}
                                </Typography>
                                <Typography variant='h6' color={theme.palette.text.secondary}>
                                    Referti mensili
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Card variant='outlined'>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                padding="2rem 0">
                                <Typography variant='h3' color={theme.palette.text.secondary}>
                                    {stats.totali}
                                </Typography>
                                <Typography variant='h6' color={theme.palette.text.secondary}>
                                    Referti totali
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                <Card variant='outlined'>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        padding="2rem 0">
                        <MaterialIcon icon="travel_explore" color={theme.palette.text.secondary} size='4rem' />
                        <Box height="1rem" />
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                            Nessuna statistica disponibile
                        </Typography>
                    </Box>
                </Card>
            )}
        </Box>
    );
};

const List: React.FC = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { openLoadingDialog, closeLoadingDialog } = useSwallowLoading();
    const navigate = useNavigate();
    const { getDeviceType } = useDeviceTypeProvider();

    const [requests, setRequests] = useState<any[]>([]);
    const [currentTime, setCurrentTime] = useState<Date>(new Date());

    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        const loadRequests = async (params?: { useDialog?: boolean }) => {
            if (params?.useDialog) {
                openLoadingDialog();
            }
            try {
                const response = await getRequests();
                setRequests(response.data);
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            } finally {
                if (params?.useDialog) {
                    closeLoadingDialog();
                }
            }
        };

        loadRequests({ useDialog: true });

        const loadRequestIntervalId = setInterval(() => {
            loadRequests({ useDialog: false });
        }, 60000);

        const timeIntervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Aggiorna il tempo corrente ogni secondo

        return () => {
            clearInterval(loadRequestIntervalId);
            clearInterval(timeIntervalId);
        };
    }, []);

    const handleAnalysisNavigation = ({ id_richiesta, id_provider }: { id_richiesta: number, id_provider: number }) => {
        navigate("/analysis", { state: { id_richiesta, id_provider } });
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        enqueueSnackbar('Funzionalità non ancora implementata', { variant: 'info' });
    };

    const servicesExiprationTime = [
        { id_servizio: 2, durata_millisecondi: 24 * 60 * 60 * 1000 },
        { id_servizio: 4, durata_millisecondi: 15 * 60 * 1000 },
        { id_servizio: 5, durata_millisecondi: 48 * 60 * 60 * 1000 },
        { id_servizio: 13, durata_millisecondi: 48 * 60 * 60 * 1000 }
    ];

    const columns: HeadCell<any>[] = [
        { id: 'id_richiesta', numeric: false, disablePadding: false, label: 'ID' },
        { id: 'id_provider', numeric: false, disablePadding: false, label: 'Provider' },
        { id: 'servizio', numeric: false, disablePadding: false, label: 'Servizio' },
        { id: 'tempo_rimanente', numeric: false, disablePadding: false, label: 'Tempo rimanente' },
        { id: 'action', numeric: true, disablePadding: false, disableSort: true, label: 'Azioni' }
    ];

    const Row = (request: any) => {
        const creationTime = new Date(request.timestamp_creazione);
        const expirationTime = new Date(creationTime.getTime() + servicesExiprationTime.find(service => service.id_servizio === request.id_servizio)?.durata_millisecondi!);
        const remainingTime = Math.max(expirationTime.getTime() - currentTime.getTime(), 0);
        const remainingTimeInSeconds = Math.floor(remainingTime / 1000);
        const remainingTimeInMinutes = Math.floor(remainingTimeInSeconds / 60);
        const remainingTimeInHours = Math.floor(remainingTimeInMinutes / 60);
        const remainingTimeInDays = Math.floor(remainingTimeInHours / 24);

        let label = '';

        if (remainingTimeInDays > 0) {
            label = `${remainingTimeInDays} giorno/i`;
        } else if (remainingTimeInHours > 0) {
            label = `${remainingTimeInHours} ora/e`;
        } else if (remainingTimeInMinutes > 0) {
            label = `${remainingTimeInMinutes} minuto/i`;
        } else if (remainingTimeInSeconds > 0) {
            label = `${remainingTimeInSeconds} secondo/i`;
        } else {
            label = 'Scaduta';
        }

        const isExpiring = remainingTimeInMinutes < 15;
        const couldExpire = remainingTimeInMinutes < 60;
        const textColor = isExpiring ? theme.palette.error.main :
            couldExpire ? theme.palette.warning.main :
                theme.palette.text.primary;

        return (
            <TableRow key={request.id_richiesta}>
                <TableCell
                    sx={{
                        color: textColor
                    }}>
                    {request.id_richiesta}
                </TableCell>
                <TableCell
                    sx={{
                        color: textColor
                    }}>
                    {request.nome_provider}</TableCell>
                <TableCell
                    sx={{
                        color: textColor
                    }}>{request.nome_servizio}
                </TableCell>
                <TableCell
                    sx={{
                        color: textColor
                    }}>
                    {label}
                </TableCell>
                <TableCell align='right'>
                    <IconButton onClick={() => handleAnalysisNavigation({ id_richiesta: request.id_richiesta, id_provider: request.id_provider })}>
                        <MaterialIcon icon="visibility" />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    const filteredRequests = requests.filter(request => {
        return request.id_richiesta.toString().includes(search) ||
            request.nome_provider.toLowerCase().includes(search.toLowerCase()) ||
            request.nome_servizio.toLowerCase().includes(search.toLowerCase());
    });

    return (
        <Box>
            <Typography variant="h5" color={theme.palette.text.primary} fontWeight="bold">Elenco richieste in attesa</Typography>
            <Box height="1rem" />
            <Card
                sx={{
                    padding: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                }}
                variant='outlined'>
                <FormControl fullWidth={getDeviceType() === 'mobile' ? true : false}>
                    <OutlinedInput
                        id='search'
                        placeholder='Cerca richieste'
                        value={search}
                        onChange={handleSearchChange}
                        startAdornment={<MaterialIcon icon='search' color={theme.palette.text.secondary} sx={{ marginRight: ".5rem" }} />}
                    />
                </FormControl>
            </Card>
            <Box height="1rem" />
            <Card
                variant='outlined'>
                <SortableTable
                    rows={requests}
                    headCells={columns}
                    renderRow={Row}
                    rowsPerPageOptions={[5, 10]}
                    initialRowsPerPage={5} />
            </Card>
        </Box>
    );
};

export default Dashboard;
