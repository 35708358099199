import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

// Definizione del tipo di stato
interface ScreenInformation {
    screenWidth: number | null;
    screenHeight: number | null;
}

// Inizializza il context con un valore di default undefined
const DeviceTypeContext = createContext<{
    getScreenInformation: () => ScreenInformation;
    getDeviceType: () => 'mobile' | 'tablet' | 'desktop';
} | undefined>(undefined);

// Crea un provider
interface UserProviderProps {
    children: ReactNode;
}

export function DeviceTypeProvider({ children }: UserProviderProps) {
    const [screenInformation, setScreenInformation] = useState<ScreenInformation>({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenInformation({
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const getScreenInformation = () => {
        return screenInformation;
    }

    const getDeviceType = () => {
        if (screenInformation.screenWidth! < 500) {
            return 'mobile';
        } else if (screenInformation.screenWidth! < 1024) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    }

    return (
        <DeviceTypeContext.Provider value={{ getScreenInformation, getDeviceType }}>
            {children}
        </DeviceTypeContext.Provider>
    );
}

// Hook personalizzato per utilizzare il context
export function useDeviceTypeProvider() {
    const context = useContext(DeviceTypeContext);
    if (context === undefined) {
        throw new Error('useUserProvider must be used within a UserProvider');
    }
    return context;
}