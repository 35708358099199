import apiClient from "./base";

export const getDocumentFile = async (params: { path: string, id_provider: number }) => {
    const token = sessionStorage.getItem("token");

    const response = await apiClient.post("/ottieni_file_documento.php", params, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
    });

    return {
        data: response.data,
        headers: response.headers,
    };
}

export const analyzeDocument = async (params: { file: File }) => {
    const token = sessionStorage.getItem("token");

    const formData = new FormData();
    formData.append("file", params.file);

    const response = await apiClient.post("/ottieni_suggerimento.php", formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });

    return response.data;
}