interface MaterialIconProps {
    icon: string;
    size?: string;
    color?: string;
    iconStyle?: 'outlined' | 'rounded' | 'sharp',
    sx?: React.CSSProperties;
}

const MaterialIcon: React.FC<MaterialIconProps> = ({ icon, size, color, iconStyle = "rounded", sx = {} }) => {
    return (
        <div
            className={`material-symbols-${iconStyle}`}
            style={{
                fontSize: size,
                color: color,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                height: "fit-content",
                ...sx
            }}>
            {icon}
        </div>
    );
}

export default MaterialIcon;