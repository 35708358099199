import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserProvider } from '../providers/useUserProvider';

const useUserActivity = () => {
    // const navigate = useNavigate();
    // const timerRef = useRef<NodeJS.Timeout | null>(null);
    // const { user } = useUserProvider();

    // useEffect(() => {
    //     const resetTimer = () => {
    //         if (timerRef.current) {
    //             clearTimeout(timerRef.current);
    //         }

    //         if (!window.location.hostname.includes('localhost') || user.userType !== 1) {
    //             timerRef.current = setTimeout(() => {
    //                 navigate('/logout');
    //             }, 10 * 60 * 1000); // 10 minuti
    //         }
    //     };

    //     const handleUserActivity = () => {
    //         resetTimer();
    //     };

    //     window.addEventListener('mousemove', handleUserActivity);
    //     window.addEventListener('keydown', handleUserActivity);

    //     resetTimer();

    //     return () => {
    //         if (timerRef.current) {
    //             clearTimeout(timerRef.current);
    //         }
    //         window.removeEventListener('mousemove', handleUserActivity);
    //         window.removeEventListener('keydown', handleUserActivity);
    //     };
    // }, [navigate]);

    return null;
};

export default useUserActivity;